import "./tree-web.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";

const TreeWeb: React.FC = () => {
  const { allSitePage: data }: Queries.TreeWebQuery = useStaticQuery(graphql`
    query TreeWeb($regex: String = "/^((?!404).)*$/") {
      allSitePage(filter: { path: { regex: $regex } }) {
        nodes {
          path
        }
      }
    }
  `);
  return (
    <ul className={"m-tree-web"}>
      {data.nodes.map((node, index) => {
        return (
          <li key={index}>
            <Link to={`${node.path}`}>{node.path}</Link>
          </li>
        );
      })}
    </ul>
  );
};

export default TreeWeb;
