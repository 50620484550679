import "../styles/layouts/404.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import Content404 from "../components/Content404/Content404";
import Layout from "../components/Layout/layout";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import WeTalk from "../components/WeTalk/WeTalk";

const NotFoundPage = ({ data }: PageProps<Queries.Page404Query>) => {
  const resume: Record<string, unknown> = {
    text: "Únete al equipo profesional de Grupo Usieto",
    _type: "span",
  };
  return (
    <Layout>
      <div className="l-404">
        <Content404 />
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query Page404 {
    sanityPageJoinOurTeam {
      hero {
        title
        resume: _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
      }
      form {
        title
        subtitle
      }
    }
  }
`;
