import "./content-404.scss";

import * as React from "react";
import { PropsWithChildren } from "react";
import TreeWeb from "../TreeWeb/TreeWeb";


const Content404: React.FC = () => {
  return (
    <section className={"o-content-404"}>
      <div className={"c-content-404"}>
        <h1>Error 404 - Página no encontrada</h1>
        <h2>Quizás este aquí lo que buscas</h2>
        <TreeWeb />
      </div>
    </section>
  );
};

export default Content404;
